.curriculumBackground {
  background-color: #33b5e5;
}

@media (max-width: 575.97px) {
  .paginationCurriculumsMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationCurriculumsMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationCurriculumsMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationCurriculumsSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationCurriculumsSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationCurriculumsSmall {
    display: none !important;
  }
}

.iconDisabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.iconEnabled {
  cursor: pointer !important;
}

.btnFixed {
  position: fixed !important;
}
