/* #login .view {
  background-image: url("http://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img%20(11).jpg");
  background-size: cover;
  height: 100vh;
} */

@-webkit-keyframes autofill {
  to {
    color: rgb(156, 69, 235);
    background: transparent;
  }
}

@keyframes autofill {
  to {
    color: #000;
    background: transparent;
  }
}

#login {
  background: #9cd3ff;
  background-size: cover;
  height: 100vh;
}

.loginbtn {
  background-color: #7f6eb4 !important;
}

.loginbtn2 {
  background-color: #a54f55 !important;
}

.loginbody {
  background-color: #7f6eb4;
}

#login .card {
  background-color: rgba(229, 228, 255, 0.2);
}
#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0 !important;
}
#login .md-form label {
  color: #ffffff;
}
#login .md-form input[type="text"]:focus:not([readonly]) + label,
#login .md-form input[type="password"]:focus:not([readonly]) + label,
#login .md-form input[type="email"]:focus:not([readonly]) + label {
  color: #8edef8;
}
#login .md-form input[type="text"]:focus:not([readonly]),
#login .md-form input[type="password"]:focus:not([readonly]),
#login .md-form input[type="email"]:focus:not([readonly]) {
  border-bottom: 1px solid #8edef8;
  box-shadow: 0 1px 0 0 #8edef8;
}

.loginRegisterTitle {
  font-family: "Fredoka One", cursive;
}
