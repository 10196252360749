.chat-room .friend-list .list-group-item {
  margin-bottom: 0;
  border: 0;
  border-bottom: 1px solid;
}

.chat-room .friend-list .list-group-item:last-of-type {
  border-bottom: 0;
}

.chat-room .friend-list .list-group-item.active {
  background-color: #eeeeee;
}

.chat-room .avatar {
  height: 3rem;
  width: 3rem;
}

/* .chat-room .chat-message:nth-of-type(even) {
  flex-direction: row-reverse;
} */

.chat-room .friends-list {
  height: 570px;
}

.chat-room .scrollable-chat {
  height: 58vh;
  padding-bottom: 25px;
  margin-bottom: 15px;
}
.textdimension {
  height: 10vh;
}

.messageTextSize {
  font-size: 14px;
}

.messageTextSize2 {
  font-size: 19px;
}

.messageTextSize3 {
  font-size: 21px;
}

.messageTextSize4 {
  font-size: 12px;
  color: #1c2331;
}
.messageTextSize5 {
  font-size: 18px;
}

.linkdiv a {
  color: white;
}

.quickSand {
  font-family: "Quicksand", sans-serif;
}

.studentFontSize {
  font-size: 17px;
}

.chatMessageHover {
  transition: color 0.2s ease-in-out;
}

.chatMessageHover:hover {
  color: #81d4fa !important;
}

.robotoFont {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.3px;
}

.chatMessageCard {
  border-radius: 50%;
  width: 43px;
  height: 43px;
  background-color: white;
}

.questrialFont {
  font-family: "Questrial", sans-serif;
}

.messageCardBorder {
  border-radius: 12px;
}

.messageCardWidth {
  width: "80%" !important;
}

.nanumText {
  font-family: "Nanum Myeongjo", serif;
}

.anticDidone {
  font-family: "Antic Didone", serif;
}

.krubFont {
  font-family: "Krub", sans-serif;
  font-weight: 500;
}

.chatBubble {
  color: #00c853;
}

.ps .ps__rail-y:hover,
.ps .ps--clicking {
  background: transparent !important;
  opacity: 0.6 !important;
}

.ps__thumb-y {
  width: 6px !important;
  background-color: #aaa !important;
}

.studentListMargin {
  margin-bottom: 1px;
  border-radius: 2px;
}

/* Adjust height of the friends list on mobile */
@media (max-width: 575.97px) {
  .friends-list {
    height: 150px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .friends-list {
    height: 570px !important;
  }
}
@media (min-width: 1199.99px) {
  .friends-list {
    height: 570px !important;
  }
}

/* Remove margin on the bottom on mobile */

@media (max-width: 575.97px) {
  .cardMobile {
    margin-top: 30px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .cardMobile {
    margin-top: 15px !important;
  }
}
@media (min-width: 1199.99px) {
  .cardMobile {
    margin-top: 15px !important;
  }
}

/* Make text area small on mobile */

@media (max-width: 575.97px) {
  .textAreaMobile {
    height: 60px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .textAreaMobile {
    height: auto !important;
  }
}
@media (min-width: 1199.99px) {
  .textAreaMobile {
    height: auto !important;
  }
}
