#portal {
  padding-top: 10px;
}

.quickSand {
  font-family: "Quicksand", sans-serif !important;
}

.railwayFont {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
}

.mainColor {
  color: #9c45eb;
}

.mainEditFontSize {
  font-size: 18px;
}

.mainEditFontSize2 {
  font-size: 20px;
}

.mainEditFontSize3 {
  font-size: 26px;
}

.hintColor::placeholder {
  color: black;
}

.Prata {
  font-family: "Prata", serif;
}

.dangerIcon {
  color: red;
}

.growMessage {
  transition: all 0.2s ease-in-out;
}
.growMessage:hover {
  transform: scale(1.3);
}

.videoInput input {
  color: black !important;
  font-family: "Quicksand", sans-serif;
}
