.btn-end {
  position: absolute;
  top: 0;
  right: 0;
}

.bgc {
  background-color: #e91e63;
}

.delete-modal {
  min-height: 20rem !important;
}

.FredokaOne {
  font-family: "Fredoka One", cursive;
}

.CarterOne {
  font-family: "Carter One", cursive;
}

.listItem {
  background-color: #e91e63;
}
.listItemLight {
  background-color: #f5588d;
}
.deleteItem {
  background-color: #212121;
}

.cardProp {
  /* font-size: */
  font-family: "Carter One", cursive;
}

.darkbgc {
  background-color: #212121;
}

.hrColor {
  background-color: white !important;
}

.quickSand {
  font-family: "Quicksand", sans-serif;
}

.pinkColor {
  color: #e91e63;
}
.pinkColorBg {
  color: #e91e63;
}

.cardMarginBotton {
  margin-bottom: 30px !important;
}

.downloadDeck {
  font-size: 20px !important;
  color: white;
}

.downloadHover {
  transition: color 0.2s ease-in-out;
}

.downloadHover:hover {
  color: #e91e63 !important;
}
