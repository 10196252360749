@-webkit-keyframes autofill {
  to {
    color: rgb(15, 12, 17);
    background: transparent;
  }
}

@keyframes autofill {
  to {
    color: #000;
    background: transparent;
  }
}

@media (min-width: 1300px) {
  #sideButton {
    display: none;
  }
  .adjustHeight {
    min-height: 24rem;
  }
}
@media (min-width: 620px) and (max-width: 767px) {
  .adjustHeight {
    min-height: 23rem;
  }
}
@media (min-width: 480px) and (max-width: 620px) {
  .adjustHeight {
    min-height: 20rem;
  }
}
@media (max-width: 475px) {
  .registercard {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .adjustHeight {
    min-height: 14rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registercard {
    width: 75%;
  }
  .adjustHeight {
    min-height: 17rem;
  }
}
@media (min-width: 992px) {
  .registercard {
    width: 100%;
  }
  .adjustHeight {
    min-height: 21rem;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#landing {
  background-color: #fff;
}

#landing .navbar.scrolling-navbar.top-nav-collapse {
  background-color: rgba(156, 69, 235, 0.9) !important;
}

#landing #home {
  background: url("https://res.cloudinary.com/unnamed/image/upload/f_auto/v1578785011/Landing/Background_e6rbo0.png")
    no-repeat center center !important;
  background-size: cover;
  /* height: 100vh; */
}

#landing h6 {
  line-height: 1.7;
}

.subfont {
  color: #0092e8;
}

#landing .avatar {
  max-width: 150px;
  height: auto;
}

#landing footer.page-footer {
  background-color: #7f6eb4;
  margin-top: 0 !important;
}

.form-control {
  color: #000;
}

.form-control:focus {
  color: #000;
}
.card {
  height: 100%;
  display: flex;
}

.card-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card.card-footer {
  align-self: flex-end;
  flex: 1 1 auto;
}
.img-circle {
  border-radius: 100%;
  width: 200px;
  height: 200px;
}

#payment {
  background: #9cd3ff;
  margin-right: auto;
  margin-left: auto;
  padding-left: 150px;
  padding-right: 150px;
}
#payment22 {
  background: #9cd3ff;
  margin-right: auto;
  margin-left: auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
}
#features {
  background: white;

  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

#contact {
  background: #fff;
  margin-bottom: 0px !important;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  height: 100% !important;
}

#learnMore {
  padding-top: 50px;
  margin-bottom: -50px;
  padding-left: 50px;
  padding-right: 50px;
}

.imageMargin {
  margin-bottom: 50px;
}

.contactMargin {
  margin-top: 14px;
}

#scrollspyheight {
  min-height: 550px;
  color: black;
}
#scrollspyheight2 {
  min-height: 600px;
  color: black;
  font-size: 20px;
  line-height: 40px;
}

.list-group-horizontal {
  font-size: 12px;
}

.vcenter {
  display: inline-block;
  vertical-align: top;
  float: none;
}
.vcenter1 {
  text-align: justify;
  text-justify: inter-word;
}

.justin {
  object-fit: cover;
}

.zIndexToast {
  z-index: 1000005 !important;
}

.landingColor {
  color: #9c45eb !important;
}
.landingColorBackground {
  background-color: #9c45eb !important;
}
.languageButton :hover {
  color: rgba(255, 255, 255, 0.479) !important;
}

.notFoundFontColor {
  color: blue !important;
}

.navIndent {
  text-indent: 1.5em;
}

.navOverflow {
  overflow: visible;
}

.marginSmall {
  margin-right: "50px";
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

.landingFont {
  font-size: 16px;
  color: rgb(0, 0, 0);
}

.landingFont2 {
  font-size: 22px;
  line-height: 32px !important;
}

.cookieFooterPosition {
  position: fixed !important;
}

.cookieFooterFont {
  font-family: "Montserrat", sans-serif;
  font-size: 18px !important;
}
.cookieFooterFont2 {
  font-family: "Raleway", sans-serif;
  font-size: 15px !important;
}

.buttonFontColor {
  color: #ffff !important;
  font-family: "Quicksand", sans-serif;
}

.buttonFontColor2 {
  background-color: black !important;
  font-family: "Quicksand", sans-serif;
  color: white;
}

@media (max-width: 575.97px) {
  .learnMoreMargin {
    vertical-align: bottom !important;
    margin-left: 180px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .learnMoreMargin {
    margin-bottom: 53px !important;
  }
}
@media (min-width: 1199.99px) {
  .learnMoreMargin {
    margin-bottom: 53px !important;
  }
}
.copyrightFont {
  font-size: 18px !important;
  text-align: left !important;
}

.cookieFont {
  font-size: 20px !important;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  text-decoration: underline;
}

@media (max-width: 575.97px) {
  .cookieMarginsMobile {
    margin-left: 15px !important;
    margin-right: 15px !important;
    text-align: center !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .cookieMarginsMobile {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 1199.99px) {
  .cookieMarginsMobile {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

/* Privacy */

.privacyBackground {
  background-color: white !important;
}

.privacyIndent {
  text-indent: 35px !important;
  line-height: 35px !important;
}

.italicFont {
  font-style: italic !important;
}

.logoFont {
  font-family: "Syncopate", sans-serif;
  font-size: 18px !important;
  align-items: baseline !important;
}

.textLeft {
  text-align: left !important;
}
