.publicPageModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}
.darkbgcPublicPage {
  background-color: #bdbdbd;
}

.publicPageBackground {
  background-color: white;
  min-width: 100% !important;
  height: 100%;
  /* min-height: 120rem; */
}

.findTeacherBackground {
  background-color: white;
  min-width: 100% !important;
  height: 100%;
  min-height: 80rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 53px;
}

.Prata {
  font-family: "Prata", serif;
}

.publicPage-profile {
  position: relative;
  font-size: 1.25rem;
}

.quickSand {
  font-family: "Quicksand", sans-serif;
}

.quickSand2 {
  font-family: "Quicksand", sans-serif;
  font-size: 20px !important;
}

.appointmentFontColor {
  color: #4286f5 !important;
}

.appointmentFontColor2 {
  color: #ff0000 !important;
}

.borderFilterPublicPage {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: solid black 1px !important;
  box-shadow: none !important;
}

.quillPublicPage::placeholder {
  border: 1px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-color: black !important;
  width: 100%;
  border-radius: 3px;
  height: 200px;
  font-family: "Quicksand", sans-serif;
}

.publicPageCard {
  background-color: #f3e6ed;
}

.publicPageCard2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #ffffff !important;
  border-radius: 3px !important;
}

.hintColor::placeholder {
  color: black;
}

.pictureCard {
  background-color: transparent !important;
  border-color: black !important;
  border: 1px solid;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.publicPageCalendar {
  width: 100% !important;
  max-width: 700px;
  min-width: 200px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .publicPageCalendar {
    display: block !important;
    content: wrap;
  }
}

.publicPageHover:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.08);
}

.findTutorHover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
}

.findTutorHover:hover {
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff1ff;
}

.clearAllHover {
  transition: color 0.2s ease-in-out;
}

.clearAllHover:hover {
  color: #ffa7ff !important;
}

@media (max-width: 575.98px) {
  .socialPublicPageCard {
    width: 100%;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .socialPublicPageCard {
    width: 100%;
  }
}
@media (min-width: 1199.99px) {
  .socialPublicPageCard {
    width: 95%;
    margin-left: 53px !important;
  }
}

.preferencesFont {
  color: #424242 !important;
}

.currencyInput input {
  color: black !important;
  font-family: "Quicksand", sans-serif;
}

.statusInput input {
  color: black !important;
}

.statusInput:focus {
  border-color: grey;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(137, 14, 79, 1);
  box-shadow: inset 0 1px 1px rgba(137, 14, 79, 1), 0 0 8px rgba(137, 14, 79, 1);
}

.footerPublicPage {
  border: none !important;
}

@media (max-width: 575.97px) {
  .publicPageButton {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .publicPageButton2 {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .publicPageButton2 {
    display: none !important;
  }
}

.messagesCard {
  border: 6px double rgba(13, 49, 74, 0.57);
}

.uniqueText {
  color: #890e4f !important;
}

.uniqueBackground {
  background-color: #890e4f !important;
}

.dangerIcon {
  color: red;
}

.growMessage {
  transition: all 0.2s ease-in-out;
}
.growMessage:hover {
  transform: scale(1.3);
}

.growMessage2 {
  transition: all 0.2s ease-in-out;
}
.growMessage2:hover {
  transform: scale(1.1);
}

.messageHover {
  transition: box-shadow 0.3s;
  box-sizing: content-box;
  width: 100%;
  margin-left: 1px;
}
.messageHover:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

#profile-ex .URL-card .btn .fa:not(.fa-paper-plane) {
  position: relative;
  font-size: 1.25rem;
}

#profile-ex .card .card-footer.links-light a {
  color: #757575;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

#profile-ex .card .card-footer.links-light a:hover {
  color: #d50000;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.scrollbar-black::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.crimsonText {
  font-family: "Crimson Text", serif;
}

.nanumText {
  font-family: "Nanum Myeongjo", serif;
}

.ptsansText {
  font-family: "PT Sans", sans-serif;
}

.halantText {
  font-family: "Halant", serif;
}

.sendMessageButtonColor {
  background-color: rgb(156, 69, 235) !important;
}

.publicPageIconColor {
  color: rgb(156, 69, 235) !important;
}

.messageBtnPublicPage {
  background-color: #7f6eb4 !important;
}

.headerPublicPage {
  color: #7f6eb4 !important;
}

.countrySelect input {
  color: black !important;
  border-color: black !important;
}

.countrySelect {
  color: black !important;
}

.countrySelect2 {
  margin-top: 2px;
}

/* Footer CSS */

.cardFooter {
  overflow: hidden;
  background-color: rgba(156, 69, 235, 0.2);
  min-height: 60px;
  width: 100%;
  margin-top: 165px !important;
  margin-bottom: 50px !important;
  box-shadow: none;
}

.cardFooterFindTutors {
  overflow: hidden;
  background-color: rgba(156, 69, 235, 0.2);
  min-height: 30px;
  width: 100% !important;
  margin-top: 80px !important;
  margin-bottom: 50px !important;
  box-shadow: none;
}

.publicPageFooter {
  color: rgb(156, 69, 235) !important;
  margin-top: 10px !important;
  font-size: 21px;
}
.publicPageFooterColor {
  color: #424242 !important;
  margin-top: 10px !important;
  margin-left: 3px !important;
}

.publicPageFooterColor2 {
  color: #424242 !important;
  font-size: 18px !important;
  cursor: pointer;
}

.footerHover {
  transition: color 0.2s ease-in-out;
}

.footerHover:hover {
  color: #ffa7ff !important;
}

/* Search Area CSS */

.searchAreaCard {
  background-color: #fff1ff;
  box-shadow: none;
  border-radius: 0;
}

.searchAreaCardHeader {
  background-color: white;
}

.spacerPublicPage {
  font-family: "Abril Fatface", cursive;
  font-size: 55px;
  margin-top: 5px;
}

@media (max-width: 575.97px) {
  .spacerPublicPage {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .spacerPublicPage {
    display: contents !important;
  }
}
@media (min-width: 1199.99px) {
  .spacerPublicPage {
    display: contents !important;
  }
}

.dropDownCountries {
  overflow: visible;
}

@media (max-width: 575.97px) {
  .searchAreaCard {
    height: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .searchAreaCard {
    height: auto !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 1199.99px) {
  .searchAreaCard {
    height: 280px !important;
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (max-width: 575.97px) {
  .searchAreaCardHeader {
    width: 100%;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .searchAreaCardHeader {
    width: 100%;
  }
}
@media (min-width: 1199.99px) {
  .searchAreaCardHeader {
    width: 97%;
  }
}

@media (max-width: 575.97px) {
  .searchAreaRow {
    width: auto;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .searchAreaRow {
    width: auto !important;
  }
}
@media (min-width: 1199.99px) {
  .searchAreaRow {
    width: 1300px !important;
  }
}

@media (max-width: 575.97px) {
  .clearAllButton {
    width: 100px;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .clearAllButton {
    width: 100px !important;
  }
}
@media (min-width: 1199.99px) {
  .clearAllButton {
    width: 100px !important;
  }
}

@media (max-width: 575.98px) {
  .findTutorRow {
    width: 100%;
    margin-left: 5px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .findTutorRow {
    width: 100%;
  }
}
@media (min-width: 1199.99px) {
  .findTutorRow {
    width: 95%;
    margin-left: 53px !important;
  }
}

.filterTutorFind {
  font-family: "Arapey", serif;
  font-size: 30px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.findTutorCard {
  background-color: white;
  width: 350px;
  height: 360px;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  transition: 0.6s;
}

.findTutorCardQuickSandFont {
  font-size: 17px;
}

.findTutorCardQuickSandFont2 {
  font-size: 17px;
}

.findTutorCardLocationRowWidth {
  width: 190px;
}

.publicPageYoutube {
  width: 100%;
  height: 280px;
  margin-left: 10px;
  margin-right: 10px;
}

.levelsCommas li:not(:last-child):after {
  content: ", ";
}

.openMessageColor {
  color: #165ac9;
}

@media (max-width: 575.97px) {
  .paginationMessagesMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationMessagesMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationMessagesMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationMessagesSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationMessagesSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationMessagesSmall {
    display: none !important;
  }
}

@media (max-width: 575.97px) {
  .messageCardHeight {
    min-height: 120rem !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .messageCardHeight {
    min-height: 40rem !important;
  }
}
@media (min-width: 1199.99px) {
  .messageCardHeight {
    min-height: 40rem !important;
  }
}

.zIndexPagination {
  z-index: 1; /* integer */
}

.filterButtonColor {
  background-color: #e91e63 !important;
}

.railwayFont {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
}

.customScroll {
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.97px) {
  .saveChangesButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .saveChangesButton {
    margin-right: 0px;
  }
}
@media (min-width: 1199.99px) {
  .saveChangesButton {
    margin-right: 0px;
  }
}

@media (max-width: 575.97px) {
  .calendarAvailabilityMobile {
    width: 305px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    display: inline-block !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .calendarAvailabilityMobile {
    width: auto;
  }
}
@media (min-width: 1199.99px) {
  .calendarAvailabilityMobile {
    width: auto;
    display: inline-block !important;
  }
}

@media (max-width: 575.97px) {
  .findTutorCardMobile {
    width: auto !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .findTutorCardMobile {
    width: auto;
  }
}
@media (min-width: 1199.99px) {
  .findTutorCardMobile {
    width: auto;
  }
}

@media (max-width: 575.97px) {
  .hrOnMobile {
    border-color: #890e4f !important;
    border-width: 1px !important;
    border-bottom: 2px;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .hrOnMobile {
    border: none !important;
  }
}
@media (min-width: 1199.99px) {
  .hrOnMobile {
    border: none !important;
  }
}

@media (max-width: 575.97px) {
  .marginOnMobileURL {
    padding-right: 28px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .marginOnMobileURL {
    padding-right: 0px !important;
  }
}
@media (min-width: 1199.99px) {
  .marginOnMobileURL {
    padding-right: 0px !important;
  }
}

.shareIcon {
  font-size: 29px;
}

.anticDidone {
  font-family: "Antic Didone", serif;
}

.summaryCardMarginTop {
  margin-top: 52px;
}

.livvicFont {
  font-family: "Livvic", sans-serif;
}

.hideOverflow {
  overflow-x: hidden !important;
  padding: 0 !important;
  align-self: center !important;
}
/* Documentation Modal Colors */
.documentationModal {
  background-color: #9c45eb !important;
}

/* Keep Country Name Inline and truncate it */

.inlineCountry {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* display: inline-block !important; */
}
