.cyanText {
  color: #00bcd4;
}
.cyanBackground {
  background-color: #00bcd4;
}
.dragBackground {
  background-color: #0000ff;
}
.cyanLightBackground {
  background-color: #6effff;
}

.livvicFont {
  font-family: "Livvic", sans-serif;
}

.customizablesModal .modal-full .modal-content {
  min-height: 100vh;
  background-color: #00bcd4 !important;
  overflow: hidden;
}

.customizablesModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}

.middleVertical {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customizablesMarginBottom {
  padding-bottom: 20px;
}

.examimage {
  background-color: rgba(132, 222, 255, 1);
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 100px;
  width: 100px;
}

.customImage {
  width: 100px;
  height: 100px;

  object-fit: contain;
}

.customImage2 {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 25px;
}

.fontBold {
  font-weight: bold;
  font-weight: 900;
}

.divHeight {
  min-height: 20px;
}

.i-am-centered {
  margin: auto;
  max-width: 500px;
  z-index: 2000;
  position: relative;
}

.centered {
  position: fixed;
  width: 400px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
}

.examPlayHeight {
  min-height: 400px;
}
