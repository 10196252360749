.bgb {
  background-color: #aad6ff;
  background-size: cover;
  background-position: center;
  min-height: 325px;
}

.cardBackgroundGroups {
  background-color: #00a0f6;
}

.buttoncolor {
  background-color: #49808e;
  color: white;
}

input {
  color: white;
}

.group input {
  color: black;
}

.inputColorGroups {
  border-bottom-color: #52a8c1 !important;
}

@media (max-width: 575.97px) {
  .paginationGroupsMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationGroupsMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationGroupsMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationGroupsSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationGroupsSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationGroupsSmall {
    display: none !important;
  }
}

.wrapperSelectStudent ul {
  overflow-y: auto !important;
  max-height: 300px !important;
}

.groupListHover:hover {
  background-color: #76d5ff;
  transition: background-color 0.3s ease-in-out;
}

.btnFixed {
  position: fixed !important;
}

.activeTabColor {
  background-color: #76d5ff;
}
