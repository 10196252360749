.btn-end {
  position: absolute;
  top: 0;
  right: 0;
}

.bgcc {
  background-color: #43a047 !important;
}

.delete-modal {
  min-height: 20rem !important;
}

.canvasModal .modal-full .modal-content {
  min-height: 100vh;
  background-color: green !important;
}

.canvasModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}
.scrollHeight {
  bottom: 61px;
}

.FredokaOne {
  font-family: "Fredoka One", cursive;
}

.CarterOne {
  font-family: "Carter One", cursive;
}

.cardProp {
  /* font-size: */
  font-family: "Carter One", cursive;
}

.hrColor {
  background-color: white !important;
}

.quickSand {
  font-family: "Quicksand", sans-serif;
}

#container {
  background-color: white;
  width: 1000px;
}

.topcorner {
  position: fixed;
  top: 0;
  left: 0;
}

.custom-cur {
  cursor: url("../../../images/cursor.cur") 10 3, auto !important;
}
.eraser-cur {
  cursor: url("../../../images/eraser-cursor.cur") 10 3, auto !important;
}
