.documentationBackground {
  background-color: #ff5722;
}

.documentationModalHeader {
  background-color: #ff7e55;
}

.documentationButton {
  color: #ff7e55;
}

.documentationHover:hover {
  box-shadow: 1px 6px 7px 0 rgba(0, 0, 0, 0.35);
  transition: box-shadow 0.4s ease-in-out;
}

.documentationFont {
  font-size: 19px;
  color: rgb(0, 0, 0);
}

.documentationIcon {
  color: black;
}
.documentationIcon2 {
  color: rgba(156, 69, 235, 0.9);
}

.timeStampFont {
  color: #616161 !important;
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 575.97px) {
  .documentationFontMobile {
    font-size: 16px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .documentationFontMobile {
    font-size: 13px !important;
  }
}
@media (min-width: 1199.99px) {
  .documentationFontMobile {
    font-size: 16px !important;
  }
}
