.paymentBackground {
  background-color: #1d99e1;
}

a.linkColor:link {
  color: grey;
  font-family: "Quicksand", sans-serif;
}
a.linkColor:visited {
  color: grey;
  font-family: "Quicksand", sans-serif;
}
a.linkColor:hover {
  color: black;
  font-family: "Quicksand", sans-serif;
}

.padding-button {
  padding-right: 100px;
  padding-left: 100px;
}

.startSubscriptionSmallText {
  font-size: 13px;
}

.customScroll ::-webkit-scrollbar-track {
  border-radius: 10px;

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9b45eb38;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScroll ::-webkit-scrollbar {
  width: auto;
  background-color: #9b45eb38;
}

.customScroll ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9c45eb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.paymentConnect .modal-full .modal-content {
  overflow: hidden;
}

.paymentHover {
  transition: color 0.3s ease-in-out;
}

.paymentHover:hover {
  color: #a8a8a8 !important;
}

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9b45eb13;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: auto;
  background-color: #9b45eb13;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9b45eb38;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */
