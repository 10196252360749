.tradingBackgroundColor {
  background-color: #c158dc !important;
}

.tradingColor {
  color: #c158dc;
}
.tradingModal .modal-full .modal-content {
  min-height: 100vh;
  background-color: #c158dc !important;
  overflow: hidden;
}

.tradingModal .modal {
  opacity: 1 !important;
}

.tradingBrandColor {
  background-color: #9c45eb !important;
}

.tradingModal .modal.fade .modal-dialog {
  transform: none !important;
}
.tradingModal .modal.in .modal-dialog {
  transform: none !important;
}

.tradingModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}

.modalClearBorder .modal-footer {
  border-top: 0 none !important;
}

.tradingCard {
  background-color: #e1bee7;
}

.tradingCard1 {
  background-color: #fff9c4;
}

.tradingCard2 {
  background-color: #ff6090;
}

.tradingCard3 {
  background-color: #539ee8;
}
.tradingCard4 {
  background-color: #76d275;
}

.tradingCard5 {
  background-color: #85fbff;
}
.gameButton {
  background-color: #ffee58 !important;
  color: black;
}

.flashCardButton {
  background-color: #ff94c0 !important;
  color: black;
}

.lessonButton {
  color: black;
}

.topicButton {
  background-color: #4cc8cc !important;
  color: black;
}

.FredokaOne {
  font-family: "Fredoka One", cursive;
}

.CarterOne {
  font-family: "Carter One", cursive;
}

.hrColor {
  background-color: white !important;
}

.quickSand {
  font-family: "Quicksand", sans-serif;
}

.avatarWidth {
  width: 6rem;
}

.profileAvatarWidth {
  width: 6rem;
}

.photoWidth {
  width: 2rem;
}

.fontWeightNormal {
  font-weight: normal;
}

.dateFont {
  font-size: 14px;
}

.acme {
  font-family: "Acme", sans-serif;
}

.anticDidone {
  font-family: "Antic Didone", serif;
}

.cardAlign {
  display: flex;
  align-items: center;
}

.paragraphColor {
  color: #78379d;
  display: flex;
  justify-content: center;
}

.scoreboardFont {
  font-size: 16px;
}

.publicFont {
  font-size: 18px;
}

.tableFont {
  font-size: 20px;
}

.widthFull {
  width: 100%;
}

.widthFull2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.whiteBackground {
  background-color: white;
}

.borderStats {
  border-width: 2px !important;
}

.borderFilter {
  border-left: transparent !important;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: solid white 2px !important;
}

.cardScoreboard:hover {
  background-color: #e1bee7;
}

.statsButton {
  align-items: right;
}
.middleVertical5 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.profileCard:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.followersCard {
  background-color: transparent;
}

.badScript {
  font-family: "Bad Script", cursive;
  font-size: 18px;
}

@media (max-width: 575.97px) {
  .paginationTradingMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationTradingMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationTradingMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationTradingSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationTradingSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationTradingSmall {
    display: none !important;
  }
}

.titleFontSizeTrading {
  font-size: 19px;
}

.tradingNameHover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
}

.tradingNameHover:hover {
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
}

.tradingFontSize {
  font-size: 20px;
  color: #000080 !important;
}

.tradingFontSize2 {
  font-size: 18px;
  color: black !important;
}

.tradingFontSize3 {
  font-size: 18px !important;
  font-family: "Quicksand", sans-serif !important;
}

.tradingFontSize4 {
  font-size: 20px;
  color: black !important;
  font-family: "Quicksand", sans-serif !important;
}

.tradingFontSize5 {
  font-size: 20px;
  color: black !important;
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

.vocabularyCommas li:not(:last-child):after {
  content: ", " !important;
}

.mainFontColor {
  color: #471255;
}

.backgroundTradingLesson {
  background-color: #f9f2fa !important;
}

.backgroundTradingTopic {
  background-color: #e6feff !important;
}

.backgroundTradingGame {
  background-color: #fffad5 !important;
}

.backgroundTradingDeck {
  background-color: #ffdfe8 !important;
}

.backgroundTradingDeck2 {
  background-color: #e91e63 !important;
}
.backgroundTradingDeck3 {
  background-color: white !important;
}

.backgroundTradingExam {
  background-color: #cbe1f8 !important;
}

.backgroundTradingCurriculum {
  background-color: #d5f1d5 !important;
}

.tradingDeckCard {
  width: auto;
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 575.97px) {
  .uploadButtonPadding {
    padding-right: 11px !important;
    padding-left: 11px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .uploadButtonPadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media (min-width: 1199.99px) {
  .uploadButtonPadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 575.97px) {
  .zIndexPaginationTrading {
    overflow-y: auto !important;
    z-index: 0 !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: static !important;
  }
}

.shareIconTrading {
  font-size: 29px;
}

.growMessageTrading {
  transition: all 0.2s ease-in-out;
}
.growMessageTrading:hover {
  transform: scale(1.1);
}

.shareArrowColor {
  color: #ec407a !important;
}

.followListHover {
  transition: color 0.2s ease-in-out;
}

.followListHover:hover {
  color: #ffa7ff !important;
}

.livvicFont {
  font-family: "Livvic", sans-serif;
}

/* Report User */

.reportIcon {
  text-align: right !important;
  color: #383a3e;
  cursor: pointer;
}

.reportColor {
  background-color: #c96de0 !important;
}

/* Trading System Image */

.imageModal {
  text-align: center !important;
}

@media (max-width: 575.97px) {
  .welcomeImage {
    width: "10rem";
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .welcomeImage {
    width: "15rem";
  }
}
@media (min-width: 1199.99px) {
  .welcomeImage {
    width: "15rem";
  }
}

.asterixFont {
  font-size: 19px;
}
