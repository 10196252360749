@-webkit-keyframes autofill {
  to {
    color: rgb(15, 12, 17);
    background: transparent;
  }
}

@keyframes autofill {
  to {
    color: #000;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#landing .rgba-gradient {
  background: -webkit-linear-gradient(
    100deg,
    rgb(86, 128, 233, 1),
    rgb(90, 185, 234, 1),
    rgb(136, 96, 208, 1)
  );
}

#landing .card {
  background-color: rgb(156, 69, 235);
}

.form-control {
  color: #000;
}

.form-control:focus {
  color: #000;
}
.card {
  height: 100%;
  display: flex;
}

.card-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card.card-footer {
  align-self: flex-end;
  flex: 1 1 auto;
}
.img-circle {
  border-radius: 100%;
  width: 200px;
  height: 200px;
}

#payment {
  background: #9cd3ff;
  margin-right: auto;
  margin-left: auto;
  padding-left: 150px;
  padding-right: 150px;
}
#payment2 {
  background: #9cd3ff;
  margin-right: auto;
  margin-left: auto;
  padding-left: 150px;
  padding-right: 150px;
  padding-bottom: 20px;
}

#contact {
  background: #ffdeff;
  margin-right: auto;
  margin-left: auto;
  padding-left: 150px;
  padding-right: 150px;
  overflow: auto;
}

#learnMore {
  padding-top: 50px;
  margin-bottom: -50px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 575.97px) {
  .zIndexPaginationDashboard {
    z-index: 1; /* integer */
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .zIndexPaginationDashboard {
    z-index: 0 !important;
  }
}
@media (min-width: 1199.99px) {
  .zIndexPaginationDashboard {
    z-index: 0 !important;
  }
}

.zIndexReminderModal {
  z-index: 2 !important;
}

.cursorEventsModal {
  pointer-events: none !important;
}

.dashboardMarginTop {
  padding-top: 86px !important;
}

.reminderFontSize {
  font-size: 20px;
  color: #000080 !important;
}

.reminderFontSize2 {
  font-size: 18px;
  color: black !important;
}

.reminderFontSize3 {
  font-size: 21px !important;
}

.reminderFontSize4 {
  font-size: 20px;
  color: black !important;
  font-family: "Quicksand", sans-serif !important;
}

.reminderFontSize5 {
  font-size: 24px;
  color: black !important;
}

.reminderFontSize6 {
  font-size: 19px;
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

.dashboardFont {
  font-family: "KoHo", sans-serif;
  font-weight: 500;
}

.dashboardFontColor {
  color: #424242;
}

.dashboardInfoFontColor {
  color: #1565c0 !important;
}

.float-wrap {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 5000;
  animation: 10s slide-up;
}
.float-wrap2 {
  position: fixed;

  bottom: 0px;
  width: 99%;
  z-index: 5000;
  animation: 10s slide-up;
}

.bottomButton:hover {
  color: #9c45eb !important;
  opacity: 0.8;
  transition: 0.2s ease-in-out !important;
}

.colorBackgroundBottom {
  background-color: #9c45eb !important;
}

@media (max-width: 650px) {
  .hideBottom {
    visibility: hidden;
  }
}

@media (max-width: 575.97px) {
  .fontFlexDashboard {
    font-size: 21px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .fontFlexDashboard {
    font-size: 16px !important;
  }
}
@media (min-width: 1199.99px) {
  .fontFlexDashboard {
    font-size: 21px !important;
  }
}

@media (max-width: 575.97px) {
  .fontFlexDashboard2 {
    font-size: 21px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .fontFlexDashboard2 {
    font-size: 13px !important;
  }
}
@media (min-width: 1199.99px) {
  .fontFlexDashboard2 {
    font-size: 18px !important;
  }
}

.removeUl ul {
  visibility: hidden;
}

.btnFixed {
  position: fixed !important;
}

/* Opening video dimensions media query */

@media (max-width: 575.97px) {
  .videoDimensions {
    height: auto !important;
    width: auto !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .videoDimensions {
    height: auto !important;
    width: auto !important;
  }
}
@media (min-width: 1199.99px) {
  .videoDimensions {
    height: 500px !important;
    width: 750px !important;
  }
}

div.mobileCalendarScroll {
  overflow-x: hidden !important;
}
div.mobileCalendarScroll:hover {
  overflow-x: scroll !important;
}

.customScrollColorCalendar ::-webkit-scrollbar-track {
  border-radius: 10px;

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9b45eb38;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScrollColorCalendar ::-webkit-scrollbar {
  width: auto;
  background-color: #9b45eb38;
}

.customScrollColorCalendar ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9c45eb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.facebookDashboard {
  background-color: #446db0 !important;
}
