.lessonsModal .modal-full .modal-content {
  min-height: 100vh;
  background-color: #59698d !important;
  overflow: hidden;
}

.lessonsModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}

.minSizeBtn {
  width: "5px" !important;
}

.lessonsModal input {
  color: black;
}

.tableLesson input {
  color: black;
}

.backgroundLessons {
  background-color: #59698d !important;
}

.backgroundLesson2 {
  background-color: #e7f7fe !important;
}

.middleVertical2 {
  vertical-align: middle;
}
.middleVertical3 {
  text-align: center;
}

.modalBottomMargin {
  margin-bottom: 5rem;
}

.quill {
  border: 1px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-color: #59698d !important;
  width: 100%;
  border-radius: 3px;
  height: 250px;
}

.ql-bubble .ql-tooltip {
  z-index: 1;
}

.ql-editor {
  font-size: 18px;
}

.pushSelectcolor {
  color: blue;
}
#fixMargin {
  padding: 0;
  margin-bottom: 10px;
}

.hideDoubleScroll {
  overflow-y: hidden !important;
}

modal {
  overflow-y: auto;
}
.modal-open {
  padding-right: 0 !important;
}

/* body {
  overflow-y: hidden !important;
} */

.timer {
  text-align: left;
}

.switch-info label input[type="checkbox"]:checked + .lever {
  background-color: #33b5e5;
}
.inputBlueLesson input {
  color: #0275d8;
}

.inputBlueLesson {
  color: #0275d8;
}

.krubFont {
  font-family: "Krub", sans-serif;
  font-weight: 500;
}

.lessonFontSize {
  font-size: 20px;
  color: #000080 !important;
}

.lessonFontSize2 {
  font-size: 18px;
  color: black !important;
}

.lessonFontSize3 {
  font-size: 18px !important;
  font-family: "Quicksand", sans-serif !important;
}

.lessonFontSize4 {
  font-size: 20px;
  color: black !important;
  font-family: "Quicksand", sans-serif !important;
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

.vocabularyCommas li:not(:last-child):after {
  content: ", " !important;
}

.tableFont {
  font-size: 17px !important;
}

.tableFont2 {
  font-size: 15px !important;
}

@media (max-width: 575.97px) {
  .paginationLessonsMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationLessonsMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationLessonsMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationLessonsSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationLessonsSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationLessonsSmall {
    display: none !important;
  }
}

.vocabRow {
  vertical-align: middle;
}

@media (max-width: 575.97px) {
  .footerMobile {
    height: 132px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .footerMobile {
    height: auto !important;
  }
}
@media (min-width: 1199.99px) {
  .footerMobile {
    height: auto !important;
  }
}

@media (max-width: 575.97px) {
  .topicFooterMobile {
    margin-right: 57px !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .topicFooterMobile {
    margin-right: 20px !important;
  }
}
@media (min-width: 1199.99px) {
  .topicFooterMobile {
    margin-right: 20px !important;
  }
}

.lessonInput input {
  color: black !important;
  font-family: "Quicksand", sans-serif;
}

.skypeColor {
  color: #00aff0 !important;
}

/* Grow effect skype button*/
.growSkype {
  transition: all 0.2s ease-in-out;
}
.growSkype:hover {
  transform: scale(1.3);
}

.dropDownStudents ul {
  overflow-y: auto !important;
  height: 400px;
}

/* Admin page */

.adminPageMarginTop {
  margin-top: 120px !important;
}

.submitButtonAdmin {
  background-color: #9c45eb !important;
}

.btnFixed {
  position: fixed !important;
}

/* Image Card Width  */

@media (max-width: 575.97px) {
  .cardWidth {
    min-width: auto !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .cardWidth {
    min-width: auto !important;
  }
}
@media (min-width: 1199.99px) {
  .cardWidth {
    min-width: 65rem !important;
  }
}

@media (max-width: 575.97px) {
  .imageFlex {
    width: 15rem !important;
    height: 15rem !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .imageFlex {
    width: 25rem !important;
    height: 25rem !important;
  }
}
@media (min-width: 1199.99px) {
  .imageFlex {
    width: 25rem !important;
    height: 25rem !important;
  }
}

.footerFont {
  color: #9c45eb !important;
}

.lessonFooterZ {
  z-index: 2;
}

.lessonScrollInput ::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

.lessonScrollInput ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: grey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
