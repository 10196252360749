.gamesModal .modal-full .modal-content {
  min-height: 100vh;
  background-color: #ffee58 !important;
  overflow: hidden;
}

.gamesModal .modal-footer {
  border-top: 0 none !important;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
}

.gamesModal input {
  color: black;
}

.tableGame input {
  color: black;
}

.backgroundGames {
  background-color: #ffee58 !important;
}

.middleVertical2 {
  vertical-align: middle;
}

.modalBottomMargin {
  margin-bottom: 5rem;
}

.gameQuill {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-color: #ffee58 !important;
  width: 100%;
  border-radius: 3px;
  height: 200px;
}

.gameButton {
  background-color: #ffee58 !important;
  color: black;
}

.row1 {
  text-align: left;
  margin: 0 auto;
}

.row1 .col-md-4 {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.gameFontSize {
  font-size: 20px;
  color: #000080 !important;
}

.gameFontSize2 {
  font-size: 18px;
  color: black !important;
}

.gameFontSize3 {
  font-size: 18px !important;
  font-family: "Quicksand", sans-serif !important;
}

.gameFontSize4 {
  font-size: 20px;
  color: black !important;
  font-family: "Quicksand", sans-serif !important;
}

.montserratFont {
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 575.97px) {
  .paginationGamesMedium {
    display: none !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationGamesMedium {
    display: flex !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationGamesMedium {
    display: flex !important;
  }
}

@media (max-width: 575.97px) {
  .paginationGamesSmall {
    display: flex !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1199.98px) {
  .paginationGamesSmall {
    display: none !important;
  }
}
@media (min-width: 1199.99px) {
  .paginationGamesSmall {
    display: none !important;
  }
}

.btnFixed {
  position: fixed !important;
}
